/* src/app/pages/pending-approval/pending-approval.page.scss */
.logo-brand {
  width: 70%;
  display: block;
  margin: 15% auto auto;
}
.logo-brand img {
  width: 100%;
  object-fit: contain;
}
ion-content {
  --background: url("./media/background.jpg") 0 0/100% 100% no-repeat;
}
.logo {
  width: 60%;
  margin: auto;
  display: block;
}
h2 {
  text-align: center;
  color: var(--text-primary);
  font-weight: 300;
  margin-top: 100px;
  font-size: 2em;
}
p {
  color: var(--text-primary);
  font-weight: 300;
  text-align: center;
  font-size: 1.3em;
  margin-top: 50px;
}
.lp-clients div {
  flex-grow: 1;
  width: 33%;
  filter: grayscale(100%);
  opacity: 0.3;
}
.lp-clients {
  display: flex;
  flex-wrap: wrap;
}
/*# sourceMappingURL=pending-approval.page.css.map */
