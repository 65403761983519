import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-pending-approval',
  templateUrl: './pending-approval.page.html',
  styleUrls: ['./pending-approval.page.scss'],
})
export class PendingApprovalPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
